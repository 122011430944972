const printTable = (props) => {
	return (
		<tr>
			<td>
				<center>{props.row1}</center>
			</td>
			<td>
				<center>{props.row2}</center>
			</td>
			<td>
				<center>{props.row3}</center>
			</td>
			<td>
				<center>{props.row4}</center>
			</td>
		</tr>
	);
};
export default printTable;
