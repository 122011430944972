export const SET_USER_REQUEST = 'SET_USER_REQUEST';
export const SET_DATA_SUCCESS = 'SET_DATA_SUCCESS';
export const SET_DATA_FAILURE = 'SET_DATA_FAILURE';
export const ADD_WORD_START = 'ADD_WORD_START';
export const ADD_WORD_ERROR = 'ADD_WORD_ERROR';
export const ADD_WORD_SUCCESS = 'ADD_WORD_SUCCESS';
export const ADD_WORD_RESET = 'ADD_WORD_RESET';
export const SET_DATA_START = 'SET_DATA_START';
export const ADD_NOT_FOUND = 'ADD_NOT_FOUND';
export const RESET_NOT_FOUND = 'RESET_NOT_FOUND';
export const ADD_EDIT_WORDS = 'ADD_EDIT_WORDS';
export const RESET_EDIT_WORDS = 'RESET_EDIT_WORDS';
export const DELETE_WORD = 'DELETE_WORD';

export const setUserRequest = () => {
	return {
		type: SET_DATA_SUCCESS,
	};
};

export const setDataSuccess = (data) => {
	return {
		type: SET_DATA_SUCCESS,
		payload: data,
	};
};

export const setDataFailure = (error) => {
	return {
		type: SET_DATA_FAILURE,
		payload: error,
	};
};

export const addWordStart = (values) => {
	return {
		type: ADD_WORD_START,
		payload: values,
	};
};

export const addWordError = (error) => {
	return {
		type: ADD_WORD_ERROR,
		payload: error,
	};
};

export const addWordSuccess = (data) => {
	return {
		type: ADD_WORD_SUCCESS,
		payload: data,
	};
};

export const addWordReset = () => {
	return {
		type: ADD_WORD_RESET,
	};
};
export const setDataStart = (data) => {
	return {
		type: SET_DATA_START,
		payload: data,
	};
};

export const addNotFound = (data) => {
	return {
		type: ADD_NOT_FOUND,
		payload: data,
	};
};

export const resetNotFound = () => {
	return {
		type: RESET_NOT_FOUND,
	};
};

export const addEditWords = (data) => {
	return {
		type: ADD_EDIT_WORDS,
		payload: data,
	};
};

export const resetEditWords = () => {
	return {
		type: RESET_EDIT_WORDS,
	};
};

export const deleteWord = (data) => {
	return {
		type: DELETE_WORD,
		payload: data,
	};
};
