import React from 'react';
import { useDispatch } from 'react-redux';
import { addNotFound } from '../../store/api/actions';
import { addEditWords } from '../../store/api/actions';
import { useNavigate } from 'react-router-dom';
import View from './view';
import { Resultstyle } from './style';

let result;
const RedirectToSavepage = (e, dispatch, navigate) => {
	dispatch(addNotFound(e.target.name));
	navigate('/addnewword');
};

const RedirectToEditpage = (e, dispatch, navigate) => {
	let words = JSON.parse(e.target.value);
	dispatch(addEditWords(words));
	navigate('/editword');
};

const Result = (props) => {
	const navigate = useNavigate();
	let data = props.data;
	let outputword = [];
	let showpersoarabic = props.showpersoarabic;
	const dispatch = useDispatch();
	const notfound = ' (Not found)';

	for (var i = 0; i < props.length; i++) {
		if (data[i].error) {
			if (data[i].error !== ', Not found') {
				// eslint-disable-next-line
				const notfoundtext = showpersoarabic ? data[i].error.split(',')[0] + notfound : data[i].error.split(',')[0];
				result = ' ' + notfoundtext + ' ';
				var word = data[i].error.split(',')[0];

				outputword.push(
					<Resultstyle>
						{result}
						<button className="savebtn" onClick={(e) => RedirectToSavepage(e, dispatch, navigate)} name={word}></button>
					</Resultstyle>,
				);
			}
		} else {
			const persoarabic = showpersoarabic ? data[i].persoarabic : '';
			// eslint-disable-next-line
			const desphilic = showpersoarabic ? ' (' + data[i].desphilic + ')' : data[i].desphilic;
			result = '' + persoarabic + desphilic + ' ';

			outputword.push(
				<Resultstyle>
					{result}
					<>
						<button
							className="editbtn"
							onClick={(e) => RedirectToEditpage(e, dispatch, navigate)}
							name={word}
							value={JSON.stringify(data[i])}></button>
					</>
				</Resultstyle>,
			);
		}
	}
	return <View outputword={outputword} />;
};
export default Result;
