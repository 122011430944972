import React, { useState } from 'react';
import {
	DefinitionArea,
	ErrLabel,
	SaveBTN,
	Description,
	DefinitionInput,
	DefRow,
	DefContainer,
	DefLabel,
	ErrRow,
	Wrapper,
	Icon, 
	DesphilicInput,
} from './style';

import SpecialCharsPopup from '../specials-popup/';



export const View = ({
	formik,
	popupMenuHandler,
	popupMenuHandlerdescription,
	BtnKeyboardDesphilic,
	BtnKeyboardDescription,
	keyStateDescription,
	keyStateDesphilic
 }) => {



	  
	


 
	return (
		<div>
			{/* {console.log("new render")} */}
			<Description className="Defcol-9">
				This word is not stored in our library, so thank you for your help by filling out the form below.
			</Description>

			<form onSubmit={formik.handleSubmit}>
				<DefContainer fluid>
					<DefRow>
						<DefLabel>Persoarabic</DefLabel>
						<DefinitionInput
							name="persoarabic"
							id="persoarabic"
							onBlur={formik.handleBlur}
							value={formik.values.persoarabic}
							onChange={formik.handleChange}
						/>
						{formik.errors.persoarabic && formik.touched.persoarabic ? (
							<ErrLabel className="error">{formik.errors.persoarabic}</ErrLabel>
						) : null}
					</DefRow>
						
						<DefRow>
							<DefLabel>Desphilic</DefLabel>
							<Wrapper>
								<DesphilicInput
									name="desphilic"
									id="desphilic"
									onBlur={formik.handleBlur}
									value={formik.values.desphilic}
									onChange={formik.handleChange}
								/>
								<Icon
								onClick={BtnKeyboardDesphilic}
								className="fa fa-keyboard-o fa-2x" />
							</Wrapper>
		
							{formik.errors.desphilic && formik.touched.desphilic ? (
								<ErrLabel className="error">{formik.errors.desphilic}</ErrLabel>
								) : null}
						</DefRow>
								{
									keyStateDesphilic ? <SpecialCharsPopup BtnKeyboardDesphilic={BtnKeyboardDesphilic} handleClick={popupMenuHandler({ values: formik.values, setValues: formik.setValues })}/> : null
								}

						<DefRow>
							<DefLabel>Description</DefLabel>
							<Wrapper>
								<DefinitionArea
									name="description"
									id="description"
									onBlur={formik.handleBlur}
									value={formik.values.description}
									onChange={formik.handleChange}
								/>

								<Icon
								onClick={BtnKeyboardDescription}
								className="fa fa-keyboard-o fa-2x" />
							</Wrapper>
						</DefRow>
						

						{
							keyStateDescription ? <SpecialCharsPopup BtnKeyboardDescription={BtnKeyboardDescription} handleClick={popupMenuHandlerdescription({ values: formik.values, setValues: formik.setValues })}/> : null
						}

					<DefRow>
						<DefLabel>English</DefLabel>
						<DefinitionArea
							name="english"
							id="english"
							className="Defcol-11"
							onBlur={formik.handleBlur}
							value={formik.values.english}
							onChange={formik.handleChange}
						/>
					</DefRow>
					{formik.errors.english && formik.touched.english ? (
						<ErrRow>
							<ErrLabel className="errorEN">{formik.errors.english}</ErrLabel>
						</ErrRow>
					) : null}
					<DefRow>
						<DefLabel>French</DefLabel>
						<DefinitionArea
							name="french"
							id="french"
							className="DefErrRow-11"
							value={formik.values.french}
							onChange={formik.handleChange}
						/>
					</DefRow>
					<DefRow>
						<DefLabel>German</DefLabel>
						<DefinitionArea
							name="german"
							id="german"
							className="Defcol-11"
							value={formik.values.german}
							onChange={formik.handleChange}></DefinitionArea>
					</DefRow>
					<DefRow>
						<DefLabel>Spanish</DefLabel>
						<DefinitionArea
							name="spanish"
							id="spanish"
							className="Defcol-11"
							value={formik.values.spanish}
							onChange={formik.handleChange}></DefinitionArea>
					</DefRow>
					<DefRow>
						<DefLabel>Italian</DefLabel>
						<DefinitionArea
							name="italian"
							id="italian"
							className="Defcol-11"
							value={formik.values.italian}
							onChange={formik.handleChange}></DefinitionArea>
					</DefRow>
				</DefContainer>
				<SaveBTN type="submit">Save</SaveBTN>
			</form>
		</div>
	);
};
export default View;
