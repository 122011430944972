export const alphabetArray = [
	{ persian: 'ا', desphilicUpercase: 'A', desphilicLowercase: 'a', other: '' },
	{ persian: 'آ', desphilicUpercase: 'Ä', desphilicLowercase: 'ä', other: '' },
	{ persian: 'ب', desphilicUpercase: 'B', desphilicLowercase: 'b', other: '' },
	{ persian: 'پ', desphilicUpercase: 'P', desphilicLowercase: 'p', other: '' },
	{ persian: 'ت', desphilicUpercase: 'T', desphilicLowercase: 't', other: '' },
	{ persian: 'ث', desphilicUpercase: 'Ƹ', desphilicLowercase: 'ƹ', other: 'Ҙ ǯ Ǯ ȝ Ȝ ƺ ƹ Ɛ' },
	{ persian: 'ج', desphilicUpercase: 'J', desphilicLowercase: 'j', other: '' },
	{ persian: 'چ', desphilicUpercase: 'Č', desphilicLowercase: 'č', other: 'Ċċ Çç' },
	{ persian: 'ح', desphilicUpercase: 'Ĥ', desphilicLowercase: 'ĥ', other: '' },
	{ persian: 'خ', desphilicUpercase: 'Ḱ', desphilicLowercase: 'ḱ', other: 'Ķ	ķ	Ķ' },
	{ persian: 'د', desphilicUpercase: 'D', desphilicLowercase: 'd', other: '' },
	{ persian: 'ذ', desphilicUpercase: 'Ḋ', desphilicLowercase: 'ḋ', other: 'Ď' },
	{ persian: 'ر', desphilicUpercase: 'R', desphilicLowercase: 'r', other: '' },
	{ persian: 'ز', desphilicUpercase: 'Z', desphilicLowercase: 'z', other: '' },
	{ persian: 'ژ', desphilicUpercase: 'Ĵ', desphilicLowercase: 'ĵ', other: '' },
	{ persian: 'س', desphilicUpercase: 'S', desphilicLowercase: 's', other: '' },
	{ persian: 'ش', desphilicUpercase: 'Š', desphilicLowercase: 'š', other: 'Ş' },
	{ persian: 'ص', desphilicUpercase: 'ß', desphilicLowercase: 'ß', other: '' },
	{ persian: 'ض', desphilicUpercase: 'Ż', desphilicLowercase: 'ż', other: '' },
	{ persian: 'ط', desphilicUpercase: 'Ť', desphilicLowercase: 'ť', other: '' },
	{ persian: 'ظ', desphilicUpercase: 'Ƶ', desphilicLowercase: 'ƶ', other: '' },
	{ persian: 'ع', desphilicUpercase: 'U', desphilicLowercase: 'u', other: '' },
	{ persian: 'غ', desphilicUpercase: 'Ġ', desphilicLowercase: 'ġ', other: 'Ǧǧ' },
	{ persian: 'ف', desphilicUpercase: 'F', desphilicLowercase: 'f', other: '' },
	{ persian: 'ق', desphilicUpercase: 'Q', desphilicLowercase: 'q', other: '' },
	{ persian: 'ک', desphilicUpercase: 'K', desphilicLowercase: 'k', other: '' },
	{ persian: 'گ', desphilicUpercase: 'G', desphilicLowercase: 'g', other: '' },
	{ persian: 'ل', desphilicUpercase: 'L', desphilicLowercase: 'l', other: '' },
	{ persian: 'م', desphilicUpercase: 'M', desphilicLowercase: 'm', other: '' },
	{ persian: 'ن', desphilicUpercase: 'N', desphilicLowercase: 'n', other: '' },
	{ persian: 'و', desphilicUpercase: 'V', desphilicLowercase: 'v', other: '' },
	{ persian: 'ه', desphilicUpercase: 'H', desphilicLowercase: 'h', other: '' },
	{ persian: 'ی', desphilicUpercase: 'Y', desphilicLowercase: 'y', other: '' },
	{ persian: 'ای', desphilicUpercase: 'I', desphilicLowercase: 'i', other: '' },
	{ persian: 'او', desphilicUpercase: 'Ö', desphilicLowercase: 'ö', other: 'Ô Ô Ȯ ȯ ȏ ō ŏ Ŏ' },
	{ persian: 'همزه', desphilicUpercase: 'Ü', desphilicLowercase: 'ü', other: 'Ū ū Ũ ũ Ŭ ŭ Ű ű' },
	{ persian: 'کسره', desphilicUpercase: 'E', desphilicLowercase: 'e', other: '' },
	{ persian: 'ضمه', desphilicUpercase: 'O', desphilicLowercase: 'o', other: '' },
	{ persian: 'فتحه', desphilicUpercase: 'A', desphilicLowercase: 'a', other: '' },
];

export default alphabetArray;
