import React from 'react';
import Main from '../../components/main';

export const Home = () => {
	return (
		<div>
			<Main />
		</div>
	);
};
export default Home;
