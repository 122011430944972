import React from 'react';
import { Typing } from './style';

export const View = ({ outputword }) => (
	<div>
		<Typing id="typing"> . . .</Typing>{' '}
		{outputword.map((entry) => (
			<>{entry} </>
		))}
	</div>
);

export default View;
