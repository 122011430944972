import styled from 'styled-components';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

export const Sentence = styled.div`
	margin-top: 10px;
	margin-left: 20px;
	> div > ui {
		display: block;
		height: 20px;
	}
	> div:last-child > ui > li {
		background-color: white;
		color: black;
	}
	.enui {
		width: 60%;

		> li > p {
			overflow: auto;
		}
	}

	.transcriptrow > ui {
		float: left;
	}
	.transcriptrow > ui > li {
		color: black;
		margin-top: -5px;
		height: 20px;
		white-space: nowrap;
	}
	> div > ui > li {
		float: left;
		list-style: none;
		width: 13%;
		margin-left: 50px;
		border-radius: 3px;
		color: white;
		text-align: center;
		cursor: pointer;

		background-color: #8c8c8c;
	}
`;

export const STabs = styled(Tabs)`
	font-family: BlinkMacSystemFont, 'Segoe UI', sans-serif;
	font-size: 14px;
	width: 90%;
	margin-left: 12px;
	box-sizing: border-box;
`;

export const STabList = styled(TabList)`
	list-style-type: none;
	padding: 4px;
	display: flex;
	margin: 0;
`;
STabList.tabsRole = 'TabList';

export const STab = styled(Tab)`
	margin-right: 4px;
	padding: 4px;
	user-select: none;
	width: 16%;
	cursor: pointer;
	margin-left: 5%;
	text-align: center;
	border-radius: 3px;
	color: white;
	background-color: #8c8c8c;

	:first-child {
		margin-left: 1%;
	}

	&.is-selected {
		color: white;
		background: black;
	}

	&:focus {
		outline: none;
		box-shadow: 0 0 0 2px rgba(0, 0, 255, 0.5);
	}
`;
STab.tabsRole = 'Tab';

export const STabPanel = styled(TabPanel)`
	box-sizing: border-box;
	display: none;
	min-height: 40vh;
	padding: 4px;
	margin-left: 5%;
	padding-top: 30px;
	margin-top: -5px;
	font-size: 18px;

	> div > ui > li {
		list-style: none;
		margin-bottom: 10px;
		margin-left: 5%;
		line-height: 20px;
	}
	> div:nth-child(3) {
		margin-right: 2%;
		float: right;
	}
	> div:nth-child(2) {
		margin-right: 5%;
		float: right;
	}

	&.is-selected {
		display: block;
	}
`;
STabPanel.tabsRole = 'TabPanel';
