import React from 'react';
import {  MenuItem } from 'react-contextmenu';
import {  MenuItemWrapper,Divmenu,CloseIcon } from './styles';
import { specials } from '../../desphilic';



const defaultHandleClick = (e, data) => {
  // console.log({ data });
};

export const MenuItemView = ({ item }) => <MenuItemWrapper>{item}</MenuItemWrapper>;

export const SpecialCharsPopup = ({ handleClick , BtnKeyboardDesphilic , BtnKeyboardDescription }) => (
      <Divmenu>
        {BtnKeyboardDescription ? <CloseIcon className="fa fa-times" onClick={BtnKeyboardDescription}/> : <CloseIcon className="fa fa-times" onClick={BtnKeyboardDesphilic}/> }
        
      {specials.map((special, index) => (
        <>
          <MenuItem
            key={`menu-item-upper-${index}`}
            data={{ desphilicCharacter: special.desphilicUpercase }}
            onClick={handleClick || defaultHandleClick}>
                <MenuItemView item={`${special.desphilicUpercase} , ${special.persian}`} />
          </MenuItem>
          {/* <MenuItem divider key={`menu-item-divider-${index}`} /> */}
          <MenuItem
            key={`menu-item-lower-${index}`}
            data={{ desphilicCharacter: special.desphilicLowercase }}
            onClick={handleClick || defaultHandleClick}>
                <MenuItemView item={`${special.desphilicLowercase} , ${special.persian}`} />
          </MenuItem>
        </>
      ))}
      </Divmenu>
);

export default SpecialCharsPopup;
