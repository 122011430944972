import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getData } from './store/api/api';
import { useRoutes, BrowserRouter as Router } from 'react-router-dom';
import Home from './page/home';
import Addnewword from './page/add-page';
import Savenewword from './page/save-page';
import ContactUs from './page/contact-us';
import About from './page/about';
import Alphabet from './page/alphabet/alphabet';
import Header from './components/header/header';
import EditWord from './components/editword';

export const App = ({ getData, persoarabic, italian, english, desphilic, german, french, spanish }) => {
	useEffect(() => {
		getData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	console.log({
		persoarabic,
		italian,
		english,
		desphilic,
		german,
		french,
		spanish,
	});

	let routes = useRoutes([
		{ path: '/', element: <Home /> },
		{ path: 'addnewword', element: <Addnewword /> },
		{ path: 'editword', element: <EditWord /> },
		{ path: 'savenewword', element: <Savenewword /> },
		{ path: 'contact-us', element: <ContactUs /> },
		{ path: 'alphabet', element: <Alphabet /> },
		{ path: 'about', element: <About /> },
	]);
	return routes;
};

const mapDispatchToProps = (dispatch) => {
	return {
		getData: () => dispatch(getData()),
	};
};
// const mapStateToProps = (state) => {
//   const {
//     persoarabic,
//     italian,
//     english,
//     desphilic,
//     german,
//     french,
//     spanish,
//   } = state.data;
//   return { persoarabic, italian, english, desphilic, german, french, spanish };
// };

const Connectedapp = connect(null, mapDispatchToProps)(App);
const AppWrapper = () => {
	return (
		<Router>
			<Header />
			<Connectedapp />
		</Router>
	);
};
export default AppWrapper;
