import React, { useState } from 'react';
import View from './view';

const Header = () => {
	const [menuOpen, setMenuOpen] = useState(false);
	const onClick = () => setMenuOpen((s) => !s);
	return <View menuOpen={menuOpen} onClick={onClick} />;
};

export default Header;
