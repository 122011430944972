import React from 'react';
import AddNewWord from '../../components/addnewword';

export const AddPage = () => {
	return (
		<div>
			<AddNewWord />
		</div>
	);
};
export default AddPage;
