import styled from 'styled-components';

export const MenuWrapper = styled.div`
	opacity: 1;
	background-color: #eeffdd;
	display:flex;
	text-align: center;
`;

export const MenuItemWrapper = styled.div`
	width: 80px;
	opacity: 1;
	background-color: #f6f6ff;
	padding: 10px 5px;
	border: 1px solid #676767;
	border-radius: 2px;
	margin: 0.5px;
	text-align: center;
	:hover {
		background-color: #c3c3cd;
		font-weight: bold;
		color: black;
	}
	@media (max-width: 768px) {
		font-size:15px;
		width: 40px;
		padding: 10px 5px;
	  }
`;


export const Divmenu = styled.div`
width: 40%;
z-index: 1;
display: flex;
flex-wrap: wrap;

position: absolute;
margin-left: auto;
margin-right: auto;
left: 0;
right: 0;
text-align: center;

@media (max-width: 768px) {
	width: 70%;
  }
`

export const CloseIcon = styled.i`
position: absolute;
right: 50px;
top: -20px;
background:#f6f6ff;
padding:5px;
border-radius:50%
`



