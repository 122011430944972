import React , {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { insertWord } from '../../store/api/api';
import { useFormik } from 'formik';
import { resetNotFound } from '../../store/api/actions';
import { addWordReset } from '../../store/api/actions';
import { useNavigate } from 'react-router-dom';
import View from './view';

const AddNewWord = (props) => {


	const [keyStateDesphilic , setkeyStateDesphilic] = useState(false)
	const [keyStateDescription , setkeyStateDescription] = useState(false)

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const notFoundWord = useSelector((state) => state.notFoundReducer.word);

	const validate = (values) => {
		const errors = {};
		if (!values.persoarabic) {
			errors.persoarabic = 'Required persoarabic';
		}
		if (!values.desphilic) {
			errors.desphilic = 'Required desphilic';
		}
		if (!values.english) {
			errors.english = 'Required english';
		}
		return errors;
	};

	const formik = useFormik({
		initialValues: {
			persoarabic: notFoundWord ? notFoundWord : '',
			description: '',
			desphilic: '',
			english: '',
			spanish: '',
			italian: '',
			german: '',
			french: '',
		},
		onSubmit: (values, { resetForm }) => {
			dispatch(insertWord(values));
			dispatch(resetNotFound());
			dispatch(addWordReset());
			resetForm({ values: '' });
			navigate('/savenewword');
		},
		validate,
	});
	const popupMenuHandler = ({ values, setValues }) => (e, data) => {
		// console.log({ values, setValues, e, data });
		// console.log(`${values.desphilic}${data.desphilicCharacter}`);
		setValues({ ...values, desphilic: `${values.desphilic}${data.desphilicCharacter}` });
		// console.log(setValues)
	};

	const popupMenuHandlerdescription = ({ values, setValues }) => (e, data) => {
		setValues({ ...values, description: `${values.description}${data.desphilicCharacter}` });
	};

	const BtnKeyboardDesphilic = () => {
		setkeyStateDesphilic(!keyStateDesphilic)
		setkeyStateDescription(false)
	  };
	const BtnKeyboardDescription = ()=>{
		setkeyStateDescription(!keyStateDescription)
		setkeyStateDesphilic(false)
	}

	

	return (
		<div>
			<View
				keyStateDescription={keyStateDescription}
				keyStateDesphilic={keyStateDesphilic}
				BtnKeyboardDescription={BtnKeyboardDescription}
				BtnKeyboardDesphilic={BtnKeyboardDesphilic}
				formik={formik}
				popupMenuHandler={popupMenuHandler}
				popupMenuHandlerdescription={popupMenuHandlerdescription} />;
		</div>
	);
};

export default AddNewWord;

