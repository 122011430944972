import { ADD_NOT_FOUND, RESET_NOT_FOUND } from '../actions';

const INITIAL_STATE = {
	word: '',
};

const notFoundReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ADD_NOT_FOUND:
			console.log('in reducer:' + action.payload);
			return { word: action.payload };

		case RESET_NOT_FOUND:
			return { word: '' };

		default:
			return state;
	}
};

export default notFoundReducer;
