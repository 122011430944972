import React from 'react';
import SaveNewWord from '../../components/savenewword';

function SavePage() {
	return (
		<div>
			<SaveNewWord />
		</div>
	);
}
export default SavePage;
