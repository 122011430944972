import styled from 'styled-components';

export const Container = styled.div`
	margin-top: 86px;
	margin-left: 20px;

	> textarea {
		border: 0;
		height: 166px;
		width: 90%;
		margin-left: 15px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		resize: none;
		padding-top: 20px;
		vertical-align: text-top;
		padding-left: 20px;
		font-family: 'Roboto', sans-serif;
		font-size: 22px;
	}
`;

export const HeaderText = styled.div`
	margin-top: 10%;
	margin-left: 10%;
	> p {
		color: black;
		border: 0;
		width: 90%;

		resize: none;
		padding-top: 20px;
		vertical-align: text-top;
		padding-left: 20px;
		font-family: 'Roboto', sans-serif;
		font-size: 22px;
	}
`;

export const MidText = styled.div`
	margin-left: 20%;
	display: flex;
	@media (max-width: 763px) {
		display: inline;
	}

	> div {
		margin: 30px;
	}
	> div p img {
		width: 100px;
	}
	> p {
		color: gray;
		border: 0;

		margin-right: 15%;
		text-align: justify;
		resize: none;
		padding-left: 20px;
		font-family: 'Roboto', sans-serif;
		font-size: 17px;
	}
`;

export const HeaderTag = styled.div`
	height: 98px;
	border-bottom: 1px solid #c5c5c5;
	margin-left: 30px;
	margin-right: 30px;
`;
export const TopMenu = styled.div`
	padding-top: 10px;
	box-sizing: border-box;
	@media (min-width: 768px) {
	}
	> ul {
		margin-top: 10px;
		margin-left: 86px;
		> li {
			width: 10%;
			text-align: center;
			list-style: none;
			min-width: 70px;
			float: left;
			max-height: 48px;
			border-left: 1px solid #808080;
			> a:hover {
				border-bottom: 2px solid rgb(17, 17, 54);
			}
			> a {
				display: block;
				height: 40px;
				font-size: 12px;
				line-height: 40px;
				color: #6f6f6f;
				text-decoration: none;
				line-break: loose;
				font-family: 'Roboto', sans-serif;

				@media (min-width: 768px) {
					font-size: 14px;
				}
				@media (min-width: 980px) {
					font-size: 16px;
				}
			}
		}
	}
`;
export const Logo = styled.img`
	width: 80px;
	height: 80px;
	cursor: pointer;
	position: absolute;
	@media (min-width: 768px) {
		width: 100px;
		height: 100px;
		width: 100px;
	}
`;

export const Footer = styled.div`
	display: block;
	margin-top: 70px;
	margin-left: 66px;
	> a {
		display: block;
		color: #555555;
		margin-top: 30px;
		text-decoration: none;
		line-break: loose;
		font-family: 'Roboto', sans-serif;
	}
`;

export const Alphabet = styled.div`
	margin-left: 8%;
	> div {
		color: black;
		border: 0;
		margin-left: 30px;
		resize: none;
		padding-left: 20px;
		font-family: 'Roboto', sans-serif;
		font-size: 14px;
	}
	> table {
		margin-left: 5%;
		@media (max-width: 768px) {
			margin-left: 1%;
		}
		margin-top: 50px;
		border: 1px solid #bbb;
	}
	> table th {
		border: 1px solid #aaa;
		font-family: tahoma;
		height: 30px;
		background-color: #aaa;
		color: white;
		font-size: 17px;
	}
	> table td {
		border: 1px solid #bbb;
	}
`;
