import axios from 'axios';
import { setDataSuccess, setDataFailure, setDataStart } from './actions';
import { addWordError, addWordSuccess, addWordStart, addEditWords, deleteWord } from './actions';
if (process.env.NODE_ENV === 'development') {
	axios.defaults.baseURL = 'http://localhost:3101';
}

export const getData = (value) => async (dispatch, getState) => {
	dispatch(setDataStart(value));
	try {
		const res = await axios.post('/api/transcripts', {
			text: value,
		});

		dispatch(setDataSuccess(res.data));
	} catch (err) {
		dispatch(setDataFailure(err));
	}
};

export const insertWord = (values) => async (dispatch) => {
	dispatch(addWordStart(values));
	try {
		const res = await axios.post('/api/add-word', values);

		dispatch(addWordSuccess(res.data.newWord));
	} catch (err) {
		dispatch(addWordError(err));
		console.log('ERR:  ', err);
	}
};
export const editWord = (values) => async (dispatch) => {
	try {
		const res = await axios.put('/api/word', values);

		dispatch(addEditWords(res.data.word));
	} catch (err) {
		console.log('ERR:  ', err);
	}
};

export const deleteword = (values) => async (dispatch) => {
	try {
		const res = await axios.delete('/api/word', {
			data: { persoarabic: values },
		});
		console.log('in api', res.data);
		dispatch(deleteWord(res.data));
	} catch (err) {
		console.log('ERR:  ', err);
	}
};
