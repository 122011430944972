import { DELETE_WORD } from '../actions';

const INITIAL_STATE = {
	data: {},
};

export const deleteReducer = (state = INITIAL_STATE, action = {}) => {
	switch (action.type) {
		case DELETE_WORD:
			return {
				data: action.payload,
			};

		default:
			return state;
	}
};

export default deleteReducer;
