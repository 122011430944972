import styled from 'styled-components';
import { Tabs, TabList, Tab } from 'react-tabs';

export const Singelword = styled.div`
	.result {
		background-color: white;
		color: black;
		@media (min-width: 768px) {
			font-size: 16px;
		}
		font-size: 12px;
		box-sizing: border-box;
		word-wrap: break-word;
	}
	margin-top: 10px;
	margin-left: 20px;
	> div > ui {
		display: block;
		height: 20px;
	}
	> div:last-child > ui > li {
		background-color: white;
		color: black;
	}
	.transcriptrow > ui {
		float: left;
	}
	.transcriptrow > ui > li {
		background-color: white;
		color: black;
		margin-top: 10px;
		float: none;
	}
	> div > ui > li {
		float: left;
		list-style: none;
		width: 13%;
		margin-left: 50px;
		border-radius: 3px;
		color: white;
		text-align: center;
		cursor: pointer;

		background-color: #8c8c8c;
	}
`;

export const STabs = styled(Tabs)`
	font-family: BlinkMacSystemFont, 'Segoe UI', sans-serif;
	font-size: 14px;
	width: 90%;
	margin-left: 12px;
	box-sizing: border-box;
`;

export const STabList = styled(TabList)`
	list-style-type: none;
	padding: 4px;
	display: flex;
	margin: 0;
	:last-child {
		margin-top: -5px;
	}
`;
STabList.tabsRole = 'TabList';

export const STab = styled(Tab)`
	margin-right: 4px;
	padding: 4px;
	user-select: none;
	width: 16%;
	cursor: pointer;
	pointer-events: none;
	margin-left: 5%;
	text-align: center;
	border-radius: 3px;
	color: white;
	background-color: #8c8c8c;

	:first-child {
		margin-left: 1%;
	}

	&.is-selected {
	}

	&:focus {
		outline: none;
		box-shadow: 0 0 0 2px rgba(0, 0, 255, 0.5);
	}
`;
STab.tabsRole = 'Tab';
