import React from 'react';
import { PageLayout, Description } from './style';

export const View = () => (
  <PageLayout>
    <Description className="col-9">
      Your word has been saved, thank you for helping us to complete our library. There are similar unsaved words below
      you may want to help us to add them in our library.
    </Description>

    {/* <Words>
        <ul>
          <li>
            <p>word1</p>
          </li>
          <li>
            <p>word2</p>
          </li>
          <li>
            <p>word3</p>
          </li>
          <li>
            <p>word4</p>
          </li>
        </ul>
      </Words> */}
  </PageLayout>
);

export default View;
