import React from 'react';
import { Logo, HeaderTag, TopMenu, TopMenuMobile } from './styles';
import { Link } from 'react-router-dom';
import logo from '../../image/logo.png';
import { HambargrtMenu } from './styles';


export const View = ({ menuOpen, onClick }) => (
	<>
		<HeaderTag className="row">
			<Link to="/">
				<Logo src={logo} alt="logo" className="logo col-1" />
			</Link>
			<TopMenu>
				<ul>
					<li className="col-2" key="alphabet">
						<Link to="/alphabet">Alphabet</Link>
					</li>
					<li className="col-2">
						<Link to="/about" key="about">
							About
						</Link>
					</li>
					<li className="col-2" key="contact">
						<Link to="/contact-us">Contact us</Link>
					</li>
					<li className="col-2" key="addnewword">
						<Link to="/addnewword">Add New Word</Link>
					</li>
				</ul>
			</TopMenu>
			<HambargrtMenu onClick={onClick}>
				<div></div>
				<div></div>
				<div></div>
			</HambargrtMenu>
		</HeaderTag>
		<TopMenuMobile open={menuOpen}>
			<div>
				<ul>
					<li className="col-2" key="alphabet">
						<Link to="/alphabet">Alphabet</Link>
					</li>
					<li className="col-2">
						<Link to="/about" key="about">
							About
						</Link>
					</li>
					<li className="col-2" key="contact">
						<Link to="/contact-us">Contact us</Link>
					</li>
					<li className="col-2" key="addnewword">
						<Link to="/addnewword">Add New Word</Link>
					</li>
				</ul>
			</div>
		</TopMenuMobile>
	</>
);

export default View;
