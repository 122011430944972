import styled from 'styled-components';

export const PageLayout = styled.div`
	margin-top: 30px;
	margin-left: 30px;
`;

export const Description = styled.p`
	color: #676767;
	font-size: 20px;
	margin-top: 100px;
	margin-left: 5%;
	text-align: center;
`;

export const Words = styled.div`
	margin-left: 60px;
	> ul > li {
		margin-top: 30px;
		list-style: none;
		> p {
			border-bottom: 1px solid #676767;
			color: #676767;
			font-size: 18px;
		}
	}
`;
