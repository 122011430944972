import React from 'react';
import { Container, HeaderText, MidText } from '../styles';

const About = () => {
	return (
		<div>
			<Container>
				<HeaderText>
					<p>What is desphilic</p>
				</HeaderText>
				<MidText>
					<p>
						Desphilic is a romanization for languages written in perso-arabic family of scripts. perso-arabic scripts
						are based in middle east and are written from right to left. Apart from the direction which is different and
						is difficult in computer, the script itself makes reading those languages difficult for rest of the world
						especially people who are less familiar for example European people or people or western countries with
						native Roman scripts. Desphilic website actually transcripts the perso-arabic script into roman like
						standard script defined for this purpose. Words transcriptions are defined by expert or people knowing about
						the words. This can be used by western audience to be able to both pronounce and read perso-arabic
						transcript and after that there might be definitions of those words in other languages.
					</p>
				</MidText>

				<HeaderText>
					<p>Why using Desphilic alphabet?</p>
				</HeaderText>
				<MidText>
					<p>
						Desphilic is a translator from persoarabic alphabet to latin, that tries to simplify learning Persian with a
						global familiar alphabet.
					</p>
				</MidText>
			</Container>
		</div>
	);
};
export default About;
