import styled from 'styled-components';
import editicon from '../../image/edit.png';
import deleteicon from '../../image/delete.png';
import saveicon from '../../image/save.png';

export const Resultstyle = styled.div`
	cursor: unset;
	text-decoration: none;
	margin-right: 10px;
	float: left;

	@media (max-width: 731px) {
		font-size: 16px;
	}

	.savebtn {
		background-image: url(${saveicon});
		width: 24px;
		height: 24px;
		:hover {
			opacity: 80%;
		}
	}

	.editbtn {
		background-image: url(${editicon});
		width: 24px;
		height: 24px;
		:hover {
			opacity: 100%;
		}
	}

	.deletebtn {
		background-image: url(${deleteicon});
		width: 24px;
		height: 24px;
		:hover {
			opacity: 100%;
		}
	}
	&:hover button {
		opacity: 50%;
	}

	> button {
		cursor: pointer;
		opacity: 1%;
		margin-left: -2px;
		border: none;
		margin-left: 2px;
		position: relative;
	}
`;

export const Typing = styled.div`
	opacity: 0;
	transition-duration: 1s;
	float: right;
	font-size: 30px;
	position: relative;
	top: -10px;
`;
