import { ADD_WORD_ERROR, ADD_WORD_START, ADD_WORD_SUCCESS, ADD_WORD_RESET } from '../actions';

const INITIAL_STATE = {
	loading: false,
	data: {},
	error: '',
};

export const addReducer = (state = INITIAL_STATE, action = {}) => {
	// nsole.log({ action, state });

	switch (action.type) {
		case ADD_WORD_START:
			return {
				...state,
				loading: true,
			};

		case ADD_WORD_SUCCESS:
			return {
				loading: false,
				data: action.payload,
				error: '',
			};
		case ADD_WORD_ERROR:
			return {
				loading: false,
				data: {},
				error: action.payload,
			};
		case ADD_WORD_RESET:
			return INITIAL_STATE;

		default:
			return state;
	}
};

export default addReducer;
