import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editWord as edit } from '../../store/api/api';
import { useFormik } from 'formik';
import { resetEditWords } from '../../store/api/actions';
import View from './view';
import { useNavigate } from 'react-router-dom';

const EditWord = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const editWord = useSelector((state) => state.editWordReducer.data);

	const validate = (values) => {
		const errors = {};
		if (!values.persoarabic) {
			errors.persoarabic = 'Required persoarabic';
		}
		if (!values.desphilic) {
			errors.desphilic = 'Required desphilic';
		}
		if (!values.english) {
			errors.english = 'Required english';
		}
		return errors;
	};

	const formik = useFormik({
		initialValues: {
			persoarabic: editWord ? editWord.persoarabic : '',
			desphilic: editWord ? editWord.desphilic : '',
			description: editWord ? editWord.description : '',
			english: editWord ? editWord.english : '',
			spanish: editWord ? editWord.spanish : '',
			italian: editWord ? editWord.italian : '',
			german: editWord ? editWord.german : '',
			french: editWord ? editWord.french : '',
		},
		onSubmit: (values, { resetForm }) => {
			dispatch(edit(values));
			dispatch(resetEditWords());
			resetForm({ values: '' });
			navigate('/savenewword');
			// window.location.reload();
		},
		validate,
	});

	const popupMenuHandler =
		({ values, setValues }) =>
		(e, data) => {
			// console.log({ values, setValues, e, data });
			// console.log(`${values.desphilic}${data.desphilicCharacter}`);
			setValues({ ...values, desphilic: `${values.desphilic}${data.desphilicCharacter}` });
		};

		const popupMenuHandlerdescription =
		({ values, setValues }) =>
		(e, data) => {
			// console.log({ values, setValues, e, data });
			// console.log(`${values.desphilic}${data.desphilicCharacter}`);
			setValues({ ...values, description: `${values.description}${data.desphilicCharacter}` });
		};

	return <View formik={formik} popupMenuHandler={popupMenuHandler} popupMenuHandlerdescription={popupMenuHandlerdescription} />;
};

export default EditWord;
