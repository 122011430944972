import React from 'react';
import { useSelector } from 'react-redux';
import { Singelword } from './singelword';
import { Sentence } from './sentence';
import { wordDataSelector } from '../../store/selectors';

const AllTranscript = () => {
	let singelword = false;
	let sentence = false;
	const data = useSelector(wordDataSelector);

	for (var i = 0; i < data.length; i++) {
		if (data[i] && !data[i].error)
			if (singelword) {
				sentence = true;
			} else singelword = true;
	}
	if (sentence) return <Sentence data={data} />;
	else if (singelword) return <Singelword data={data} />;
	else return <div></div>;
};

export default AllTranscript;
