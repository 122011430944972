import React, { useState, useEffect } from 'react';
import { getData } from '../../store/api/api';
import { useSelector, useDispatch } from 'react-redux';
import { wordDataSelector } from '../../store/selectors';
import View from './view';

const Main = () => {
	const [inputField, setInputField] = useState('');
	const typing = document.getElementById('typing');
	const [checked, setChecked] = useState(true);

	const handleChange = () => {
		setChecked(!checked);
	};

	const copyToClipboard = () => {
		let clipboard = '';
		words.forEach((element) => {
			if (!element.error) {
				clipboard = clipboard + ' ' + element.desphilic;
			} else clipboard = clipboard + ' ' + element.error.split(',')[0];
		});
		let cblabel = document.getElementById('clipbosrdlabel');
		cblabel.style.opacity = 100;
		setTimeout(() => {
			cblabel.style.opacity = 0;
		}, 2000);
		navigator.clipboard.writeText(clipboard);
	};

	const dispatch = useDispatch();

	const handelgetdata = () => {
		var string = inputField;
		// eslint-disable-next-line
		string = string.replace(/[&\/\\#,+`()$~%..ـ@٪؟'!!،":*?<>{}-]/g, ' ');
		dispatch(getData(string));
	};

	const words = useSelector(wordDataSelector);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (typing) typing.style.opacity = 0;
			handelgetdata();
		}, 1000);
		return () => {
			if (typing) typing.style.opacity = 100;
			clearTimeout(timer);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputField]);

	const onChange = (e) => setInputField(e.target.value);

	return (
		<View
			onChange={onChange}
			words={words}
			inputField={inputField}
			length={words.length}
			checked={checked}
			handleChange={handleChange}
			copyToClipboard={copyToClipboard}
		/>
	);
};

export default Main;
