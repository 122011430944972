export const specials = [
	{ persian: 'آ', desphilicUpercase: 'Ä', desphilicLowercase: 'ä', other: '' },
	{ persian: 'ث', desphilicUpercase: 'Ƹ', desphilicLowercase: 'ƹ', other: 'Ҙ ǯ Ǯ ȝ Ȝ ƺ ƹ Ɛ' },
	{ persian: 'چ', desphilicUpercase: 'Č', desphilicLowercase: 'č', other: 'Ċċ Çç' },
	{ persian: 'ح', desphilicUpercase: 'Ĥ', desphilicLowercase: 'ĥ', other: '' },
	{ persian: 'خ', desphilicUpercase: 'Ḱ', desphilicLowercase: 'ḱ', other: 'Ķ	ķ	Ķ' },
	{ persian: 'ذ', desphilicUpercase: 'Ḋ', desphilicLowercase: 'ḋ', other: 'Ď' },
	{ persian: 'ژ', desphilicUpercase: 'Ĵ', desphilicLowercase: 'ĵ', other: '' },
	{ persian: 'ش', desphilicUpercase: 'Š', desphilicLowercase: 'š', other: 'Ş' },
	{ persian: 'ص', desphilicUpercase: 'ß', desphilicLowercase: 'ß', other: '' },
	{ persian: 'ض', desphilicUpercase: 'Ż', desphilicLowercase: 'ż', other: '' },
	{ persian: 'ط', desphilicUpercase: 'Ť', desphilicLowercase: 'ť', other: '' },
	{ persian: 'ظ', desphilicUpercase: 'Ƶ', desphilicLowercase: 'ƶ', other: '' },
	{ persian: 'غ', desphilicUpercase: 'Ġ', desphilicLowercase: 'ġ', other: 'Ǧǧ' },
	{ persian: 'او', desphilicUpercase: 'Ö', desphilicLowercase: 'ö', other: 'Ô Ô Ȯ ȯ ȏ ō ŏ Ŏ' },
	{ persian: 'همزه', desphilicUpercase: 'Ü', desphilicLowercase: 'ü', other: 'Ū ū Ũ ũ Ŭ ŭ Ű ű' },
];

export default specials;
