import React from 'react';
import { Container, MidText } from '../styles';
import phone from '../../image/phone.png';
import facebook from '../../image/facebook.png';
import email from '../../image/mail.png';

const ContactUs = () => {
	return (
		<div>
			<Container>
				<MidText>
					<div>
						<p>
							<center>
								<img src={phone} alt="phone" />
							</center>
						</p>
						<p>
							<center> +61-000000000000</center>
						</p>
					</div>

					<div>
						<p>
							<center>
								<img src={email} alt="email" />
							</center>
						</p>
						<p>
							<center> info@desphilic.com</center>
						</p>
					</div>
					<div>
						<p>
							<center>
								<img src={facebook} alt="facebook" />
							</center>
						</p>
						<p>
							<center> www.facebook.com/desphilic</center>
						</p>
					</div>
				</MidText>
			</Container>
		</div>
	);
};
export default ContactUs;
