import React from 'react';
import { Sentence, STabs, STab, STabList, STabPanel } from './style';

export const View = ({ data }) => (
	<Sentence>
		<STabs selectedTabClassName="is-selected" selectedTabPanelClassName="is-selected">
			<STabList>
				<STab key="en">En</STab>
				<STab key="fr">Fr</STab>
				<STab key="ge">Ge</STab>
				<STab key="sp">Sp</STab>
				<STab key="it">It</STab>
			</STabList>
			<STabPanel>
				<div className="transcriptrow">
					<ui key="1" className="enui">
						{' '}
						{data.map((entry, index) => (
							<li key={`en-li-${index}`}>
								<p>{entry.english}</p>{' '}
							</li>
						))}
					</ui>
				</div>
				<div className="transcriptrow">
					<ui>
						{' '}
						{data.map((entry, index) => (
							<li key={`pa-li-${index}`}>
								<p>{entry.persoarabic}</p>{' '}
							</li>
						))}
					</ui>
				</div>
				<div className="transcriptrow">
					<ui>
						{' '}
						{data.map((entry, index) => (
							<li key={`de-li-${index}`}>
								<p>{entry.desphilic}</p>{' '}
							</li>
						))}
					</ui>
				</div>
			</STabPanel>
			<STabPanel>
				<div className="transcriptrow">
					<ui>
						{' '}
						{data.map((entry, index) => (
							<li key={`fr-li-${index}`}>
								{entry.french}
								<br />{' '}
							</li>
						))}
					</ui>
				</div>
				<div className="transcriptrow">
					<ui>
						{' '}
						{data.map((entry, index) => (
							<li key={`pa2-li-${index}`}>{entry.persoarabic} </li>
						))}
					</ui>
				</div>
				<div className="transcriptrow">
					<ui>
						{' '}
						{data.map((entry, index) => (
							<li key={`pa2-li-${index}`}>{entry.desphilic} </li>
						))}
					</ui>
				</div>
			</STabPanel>
			<STabPanel>
				<div className="transcriptrow">
					<ui>
						{' '}
						{data.map((entry, index) => (
							<li key={`pa2-li-${index}`}>
								{entry.german} <br />
							</li>
						))}
					</ui>
				</div>
				<div className="transcriptrow">
					<ui>
						{' '}
						{data.map((entry, index) => (
							<li key={`pa2-li-${index}`}>{entry.persoarabic} </li>
						))}
					</ui>
				</div>
				<div className="transcriptrow">
					<ui>
						{' '}
						{data.map((entry) => (
							<li>{entry.desphilic} </li>
						))}
					</ui>
				</div>
			</STabPanel>
			<STabPanel>
				<div className="transcriptrow">
					<ui>
						{' '}
						{data.map((entry) => (
							<li>
								{entry.spanish}
								<br />{' '}
							</li>
						))}
					</ui>
				</div>
				<div className="transcriptrow">
					<ui>
						{' '}
						{data.map((entry) => (
							<li>{entry.persoarabic} </li>
						))}
					</ui>
				</div>
				<div className="transcriptrow">
					<ui>
						{' '}
						{data.map((entry) => (
							<li>{entry.desphilic} </li>
						))}
					</ui>
				</div>
			</STabPanel>
			<STabPanel>
				<div className="transcriptrow">
					<ui>
						{' '}
						{data.map((entry) => (
							<li>
								{entry.italian} <br />
							</li>
						))}
					</ui>
				</div>
				<div className="transcriptrow">
					<ui>
						{' '}
						{data.map((entry) => (
							<li>{entry.persoarabic} </li>
						))}
					</ui>
				</div>
				<div className="transcriptrow">
					<ui>
						{' '}
						{data.map((entry) => (
							<li>{entry.desphilic} </li>
						))}
					</ui>
				</div>
			</STabPanel>
		</STabs>
	</Sentence>
);
export default View;
