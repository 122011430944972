import React from 'react';
import { InputMain, OutputMain, Checkbox, Copybtn, ClipboardLabel } from './style';
import { Container, Footer } from '../../page/styles';
import AllTranscript from '../alltranscript/allTransCript';
import Result from '../result/result';

export const View = ({ inputField, onChange, words, length, checked, handleChange, copyToClipboard }) => (
	<div>
		<Container>
			<ClipboardLabel id="clipbosrdlabel">Desphilic Copy To Clipboard</ClipboardLabel>
			<InputMain
				placeholder="Type here"
				className="col-9"
				type="text"
				name="Inputtext"
				value={inputField}
				id="input"
				onChange={onChange}></InputMain>
			<Copybtn onClick={copyToClipboard}>Copy Desphilic</Copybtn>
			<Checkbox>
				<label className="container">
					<input type="checkbox" checked={checked} onChange={handleChange} />
					<span className="checkmark"></span>
					Show Persoarabic
				</label>
			</Checkbox>
			<OutputMain>
				<Result data={words} length={length} showpersoarabic={checked}></Result>
			</OutputMain>
		</Container>
		<AllTranscript />
		<Footer></Footer>
	</div>
);

export default View;
