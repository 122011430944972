import { ADD_EDIT_WORDS, RESET_EDIT_WORDS } from '../actions';

const INITIAL_STATE = {
	data: {},
	error: '',
};

export const editWordReducer = (state = INITIAL_STATE, action = {}) => {
	switch (action.type) {
		case ADD_EDIT_WORDS:
			return {
				data: action.payload,
			};
		case RESET_EDIT_WORDS:
			return {
				INITIAL_STATE,
			};

		default:
			return state;
	}
};
export default editWordReducer;
