import styled from 'styled-components';

export const HeaderTag = styled.div`
	@media (max-width: 820px) {
		height: 70px;
		margin-top: -18px;
	}
	height: 98px;
	border-bottom: 1px solid #c5c5c5;
	margin-left: 30px;
	margin-right: 30px;
`;

export const TopMenu = styled.div`
	@media (max-width: 731px) {
		display: none;
	}

	padding-top: 10px;
	box-sizing: border-box;
	@media (min-width: 820px) {
	}
	> ul {
		margin-top: 10px;
		margin-left: 86px;

		> li {
			width: 10%;
			text-align: center;
			list-style: none;
			min-width: 70px;

			float: left;
			max-height: 48px;
			border-left: 1px solid #808080;
			:last-child {
				width: 15%;
				text-align: center;
			}
			> a:hover {
				border-bottom: 2px solid rgb(17, 17, 54);
			}
			> a {
				display: block;
				height: 40px;
				font-size: 12px;
				line-height: 40px;
				color: #6f6f6f;
				text-decoration: none;
				line-break: loose;
				font-family: 'Roboto', sans-serif;

				@media (min-width: 820px) {
					font-size: 14px;
				}
				@media (min-width: 980px) {
					font-size: 16px;
				}
			}
		}
	}
`;

export const TopMenuMobile = styled.div`
	display: ${(p) => (p.open ? 'block' : 'none')};

	box-sizing: border-box;

	@media (min-width: 731px) {
		display: none;
	}
	> div {
		border-bottom: 1px solid #c5c5c5;
		margin-left: 30px;
		margin-right: 30px;
	}
	> div ul {
		margin-top: 10px;
		margin-left: -40px;
		:last-child {
			width: 30%;
		}

		> li {
			width: 10%;
			text-align: left;
			padding-left: 10px;
			list-style: none;
			min-width: 70px;

			max-height: 48px;
			border-left: 1px solid #808080;

			> a:hover {
				border-bottom: 2px solid rgb(17, 17, 54);
			}
			> a {
				display: block;
				height: 40px;
				font-size: 12px;
				line-height: 40px;
				color: #6f6f6f;
				text-decoration: none;
				line-break: loose;
				font-family: 'Roboto', sans-serif;

				@media (min-width: 820px) {
					font-size: 14px;
				}
				@media (min-width: 980px) {
					font-size: 16px;
				}
			}
		}
	}
`;

export const Logo = styled.img`
	width: 80px;
	height: 80px;
	cursor: pointer;
	position: absolute;
	margin-top: 5px;

	@media (min-width: 820px) {
		width: 100px;
		height: 100px;
		width: 100px;
	}
`;

export const HambargrtMenu = styled.div`
	@media (min-width: 731px) {
		display: none;
	}
	margin: auto 0 auto auto;
	margin-top: 20px;
	float: right;
	width: 25px;
	min-width: 25px;
	padding: 5px;
	> div {
		height: 3px;
		min-height: 3px;
		width: 100%;
		background: black;
		margin: 5px 0;
		border-radius: 5px;
	}
`;
