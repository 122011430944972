import React from 'react';
import { Alphabet } from '../styles';
import Printtable from './print-table';
import { alphabet } from '../../desphilic';

const AlphabetTable = () => {
	return (
		<Alphabet>
			<table>
				<tr>
					<th width="20%">
						<center>persian</center>
					</th>
					<th width="20%">
						<center>Desphilic Upper case</center>
					</th>
					<th width="20%">
						<center>Desphilic lower case</center>
					</th>
					<th width="20%">
						<center>Other</center>
					</th>
				</tr>
				{alphabet.map((item, index) => (
					<Printtable
						key={`alpahbet-row-${index}`}
						row1={item.persian}
						row2={item.desphilicUpercase}
						row3={item.desphilicLowercase}
						row4={item.other}
					/>
				))}
			</table>
		</Alphabet>
	);
};
export default AlphabetTable;
