import React from 'react';
import { STabs, STab, STabList, Singelword } from './style';

export const View = ({ data }) => (
	<Singelword>
		<STabs selectedTabClassName="is-selected" selectedTabPanelClassName="is-selected">
			<STabList>
				<STab key="en">En</STab>
				<STab key="fr">Fr</STab>
				<STab key="ge">Ge</STab>
				<STab key="sp">Sp</STab>
				<STab key="it">It</STab>
			</STabList>
			<STabList>
				<STab key="enr" className="result">
					{data[0].english}
				</STab>
				<STab key="frr" className="result">
					{data[0].french}
				</STab>
				<STab key="ger" className="result">
					{data[0].german}
				</STab>
				<STab key="spr" className="result">
					{data[0].spanish}
				</STab>
				<STab key="itr" className="result">
					{data[0].italian}
				</STab>
			</STabList>
		</STabs>
	</Singelword>
);
export default View;
