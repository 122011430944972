import styled from 'styled-components';
import { Row, Col, Container } from 'react-bootstrap';

export const SaveBTN = styled.button`
	border: none;
	border-bottom: 1px solid black;
	background-color: transparent;
	font-size: 20px;
	margin: 20px;
	margin-left: 70%;
	cursor: pointer;
	transition-duration: 0.4s;
	:hover {
		border: 1px solid black;
	}
	:active {
		background-color: azure;
		transform: translateY(2px);
	}
`;

export const DefinitionInput = styled.input`
	width: 99.3%;
	height: 33px;
	margin-top: 6px;
	display: block;
	font-size: 18px;
	min-width: 100px;
`;



export const DefinitionArea = styled.textarea`
	height: 70px;
	width: 99.5%;
	margin-top: 6px;
	display: 'block';
	font-size: 16px;
	resize: vertical;
	min-width: 100px;
`;

export const Description = styled.p`
	color: #676767;
	font-size: 20px;
	margin-top: 100px;
	margin-left: 5%;
`;

export const DefLabel = styled.label`
	color: #676767;
	display: inline;
	margin-left: 10px;
	margin-right: 10px;
	min-width: 100px;
`;
export const ErrLabel = styled.label`
	font-size: 12px;
	color: red;
	display: block;
	margin-left: 120px;
	margin-top: 45px;
	margin-right: 10px;
	min-width: 200px;
	position: absolute;
`;

export const DefRow = styled(Row)`
	justify-content: space-between;
	display: flex;
	margin-top: 16px;
	margin-bottom: 16px;
`;

export const DefCol = styled(Col)`
	display: flex;
`;

export const ErrRow = styled(Row)`
	margin-bottom: 10px;
	.errorEN {
		margin-top: -16px;
	}
`;

export const DefContainer = styled(Container)`
	max-width: 90%;
	margin-top: 50px;
`;


	export const DesphilicInput = styled(DefinitionInput)`
	min-width: 100px;
	width:99.3% !important;
	padding:0px;
	margin:0px;
`;

export const Wrapper = styled.div`
position:relative;
width:100%;
padding:0px;
margin:0px;

`;


export const Icon = styled.i`
position: absolute;
top:50%;
right:10px;
transform: translateY(-50%);
color:#676767
`;

