import styled from 'styled-components';
import clipboard from '../../image/clipboard.png';

export const InputMain = styled.textarea`
	border: 0;
	height: 166px;
	width: 100%;
	margin-left: 15px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	resize: none;
	padding-top: 20px;
	vertical-align: text-top;
	padding-right: 20px;
	font-family: 'Roboto', sans-serif;
	font-size: 22px;
	box-sizing: border-box;
	word-spacing: 5px;
	line-height: 50px;
	display: flex;
`;

export const OutputMain = styled.div`
	border: 0;
	min-height: 166px;
	color: #8a8a8a;
	width: 90%;
	margin-left: 15px;
	margin-top: 50px;

	padding-top: 20px;
	vertical-align: text-top;
	padding-left: 20px;
	font-family: 'Roboto', sans-serif;
	font-size: 22px;
	box-sizing: border-box;
	display: flex;
`;

export const Checkbox = styled.div`
	margin-top: 4px;
	padding-left: 80%;
	@media (max-width: 731px) {
		padding-left: 70%;
	}
	> label {
		color: gray;
		white-space: nowrap;
	}
	/* The container */
	.container {
		display: block;
		position: relative;
		padding-left: 17px;
		margin-bottom: 0px;
		padding-top: 5px;
		margin-top: 8px;
		cursor: pointer;
		font-family: 'Roboto', sans-serif;
		font-size: 15px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	/* Hide the browser's default checkbox */
	.container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* Create a custom checkbox */
	.checkmark {
		position: absolute;
		top: 0;
		margin-top: 5px;

		left: 0;
		height: 15px;
		width: 15px;
		background-color: #eee;
	}

	/* On mouse-over, add a grey background color */
	.container:hover input ~ .checkmark {
		background-color: #ccc;
	}

	/* When the checkbox is checked, add a blue background */
	.container input:checked ~ .checkmark {
		background-color: gray;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */
	.container input:checked ~ .checkmark:after {
		display: block;
	}

	/* Style the checkmark/indicator */
	.container .checkmark:after {
		left: 5px;
		top: 2px;
		width: 3px;
		height: 6px;
		border: solid white;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
`;

export const Copybtn = styled.button`
	color: gray;
	float: right;
	margin-right: 22%;
	margin-top: 8px;
	height: 25px;
	border: 1px solid grey;
	background-image: url(${clipboard});
	background-repeat: no-repeat;
	padding-left: 25px;
	cursor: pointer;
	:hover {
		border: 1px solid black;
	}
	:active {
		background-color: azure;
		transform: translateY(2px);
	}
	@media (max-width: 731px) {
		margin-right: 32%;
	}
`;

export const ClipboardLabel = styled.label`
	color: grey;
	position: absolute;
	top: 142px;
	left: 80px;
	transition-duration: 2s;
	opacity: 0;
	@media (max-width: 731px) {
		top: 100px;
	}
`;
